
.app-bar-btn {
    cursor: pointer;
    user-select: none;
    border-radius: 0.5rem;
    overflow: hidden;
}

.app-bar-btn:hover {
    box-shadow: 0 0 43px 5px rgba(0,0,0,0.1);
}

.app-bar-menu-list {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.app-bar-menu-list .p-divider.p-divider-horizontal {
    margin: 0.5rem;
}

.app-bar-menu-option {
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items:center;
}

.app-bar-menu-option:hover {
    background-color: #eeeeee;
    border-radius: 0.5rem;
}