body { color: #535768; }
h1,h2,h3 { color: black; }
strong { font-weight: 400; color: rgb(51, 51, 51); }
p { margin: 4px 0 0; font-size: 0.8125rem; color: rgb(103, 104, 121);}

.welcome-main {
    position:relative;
    width: 100%;
    height: 100%;
    overflow-y:scroll;
}

.active-menu-wrapper {
    position: fixed;
    top: 72px;
    width: 100%;
    background-color: white;
    z-index: 200;
    padding-bottom: 1rem;
}

.active-menu-content {
    display: flex;
    margin-left:auto;
    margin-right:auto;
    max-width: 1400px;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: white;
    border-bottom: 1px solid rgb(233,245,247);
    width: 100%;
}

.topNavBar {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 300;
    width: 100%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;
    height: 72px;
}

.navbar-container::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 65px;
    width: 100%;
    height: 6px;
    box-shadow: 0px 4px 4px rgba(29,140,242,0.08);
    z-index: 300;
}

.app-bar-btn {
    display: none;
    color: #00a7c8;
}

.nav-section {
    display: flex;
    align-items: center;
    height: 45px;
}

.logo {
    margin-right: 1rem;
}

.nav-option {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 4px 10px;
    height: 35px;
    cursor: pointer;
}
.nav-active,
.nav-option:hover {
    background-color: rgb(233,245,247);
    color: #00A8C8;
}

.get-started-btn {
    display: flex;
    cursor: pointer;
    border-radius: 1.25rem;
    background-color: #00A8C8;
    padding: 8px 16px;
    color: white;
    margin-left: 8px;
    user-select: none;
}

.get-started-btn:hover { background-color: #00A8C8; }
.get-started-btn:hover .get-started-arr svg { margin-left: 8px; }

.get-started-arr {
    display: flex;
    align-items: center;
    width: 20px;
}

.get-started-arr svg {
    margin-left: 4px;
    transition: 0.5s;
}

.navbar-content {
    position: relative;
    display: flex;
    background-color: white;
    z-index:200;
}

.active-menu-selected-content {
    display: flex;
    padding-top: 4rem;
    padding-left: 2rem;
    z-index:200;
}

.selected-content-left {
    border-right: 1px solid rgb(236, 239, 248);
}

.sidebar-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border-radius: 0px 6px 6px 0px;
}

.sidebar-option span {
    display: flex;
    align-items: center;
}

.sidebar-option:hover {
    background-color: rgb(233,245,247);
}

.active-content-sidebar {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    box-shadow: 32px 0px 40px -32px rgba(29, 140, 242, 0.16);
}

.blur-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(58, 58, 58, 0.1);
    backdrop-filter: blur(10px);
}

.sidebar-option-list {
    display: flex;
    flex-direction: column;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.info-block {
    border-radius: 6px;
    padding: 8px;
}

.info-block:hover {
    background-color: rgb(233,245,247);
}

.welcome-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    padding: 8rem;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    justify-content: space-evenly;
}

.product-list-start {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    transform: scale(1.25);
}

.product-option {
    margin-bottom: 1.5rem;
    max-width: 300px;
    text-align: justify;
}

.h1s {
    font-size: 80px;
    text-align: center;
    line-height: 75px;
}

.h3s {
    font-size: 20px;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 80%;
}

.mt-1 { margin-top: 0.5rem; }
.ml-1 { margin-left: 0.5rem; }
.ml-1 { margin-left: 1rem; }

@media (max-width: 765px) {
    .h1s { font-size: 40px; line-height: 40px; }
    .h3s { font-size: 16px; margin-top: 2rem; width: 100%; }
    .nav-option { display: none; }
    .get-started-btn { display: none; }
    .get-started-btn.small { display: flex; }
    .app-bar-btn { display: flex; }
    .welcome-content { padding-top: 4rem; padding-left: 2rem; padding-right: 2rem; }
    .product-option { min-width: 300px; }
}
